<script>
import 'select2'
import 'select2/dist/js/i18n/ru'
import 'select2/dist/css/select2.min.css'
import '~/assets/scss/plugins/select2.scss'
export default {
	name: "SelectField",
	data:() => ({
		select2: null,
	}),
	props: {
		multiply: {
			type: Boolean,
			default: false
		},
		remote: {
			type: String,
			default: ""
		},
		name: {
			type: String,
			default: "countries[]"
		},
		label: String,
		defaultValue: {
			type: String,
			default: '',
		},
	},
	computed: {
		userSettings () {
			const fieldName = this.name
			let form = document.getElementById('requestForm');
			let data = new FormData(form);
			return {
				width:'100%',
				allowClear: true,
				placeholder:'',
				language: "ru",
				tags:this.name !== 'countries[]',
				name: this.name,
				multiple: this.multiply,
				ajax: {
					url: this.remote,
					dataType: 'json',
					delay: 250,
					language: "ru",
					method: 'post',
					processData: false,
					contentType: false,
					data: function (params) {
						console.log(params)
						let form = document.getElementById('requestForm');
						let data = new FormData(form);

						data.set(fieldName, params.term === undefined ? '' : params.term);
						data.set('page', params.page === undefined ? 1 : params.page);
						data.set('autocomplete_field', fieldName.replace('[]', ''));
						return data;
					}
				},
				templateResult: function (state) {
					if (!state.ajax) {
						return state.text;
					}
					return $(`<div>${state.text}</div>`);
				},
				templateSelection: function (state) {
					if (!state.ajax) {
						return state.text;
					}
					state.text = state.selection

					return state.text ;
				},
				minimumInputLength: 0,
			}
		},

	},
	mounted() {
		this.select2 = $(this.$el)
			.find('select')
			.select2({
				...this.userSettings,
			})
	}
}
</script>

<template>
	<div>
		<select :name="name"><slot></slot></select>
	</div>
</template>

<style scoped lang="scss">
@import '~/assets/scss/plugins/select2.scss';
</style>

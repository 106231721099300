<template>

	<div id="__layout" class="sc-login-page">
		<div class="uk-flex uk-flex-center uk-flex-middle sc-login-page-wrapper">
		<div class="uk-width-2-3@s uk-width-1-2@m uk-width-1-3@l uk-width-1-4@xl uk-margin-auto-vertical">
			<ScCard>
				<ScCardBody>
					<div id="sc-login-form" class="sc-toggle-login-register sc-toggle-login-password">
						<div class="sc-login-page-inner">
							<div class="uk-margin-medium">
								<ScInput v-model="loginData.login">
									<label>
										Email
									</label>
								</ScInput>
							</div>
							<div class="uk-margin-medium">
								<ScInput v-model="loginData.password" type="password">
									<label>
										Пароль
									</label>
								</ScInput>

							</div>
							<ul v-if="loginData.error" class="sc-vue-errors uk-display-block">
								<li>
									{{ loginData.error }}
								</li>
							</ul>
							<div class="uk-margin-large-top">
								<a @click="login" href="javascript:void(0)" class="sc-button sc-button-large sc-button-block sc-button-danger">
									Войти
								</a>

							</div>
						</div>
					</div>

				</ScCardBody>
			</ScCard>
		</div>
	</div>
	</div>
</template>

<script>
import ScInput from '~/components/Input'
import {selfApi} from "~/api";

export default {
	name: 'LoginPage',
	components: {
		ScInput
	},
	layout: 'login_page',
	data: () => ({
		loginData: {
			login: '',
			password: '',
			error: ''
		},
	}),
	methods: {
		async login () {
			this.loginData.error = ''
			if (!this.loginData.login || !this.loginData.login ) {
				this.loginData.error = 'Укажите Email и пароль'
				return
			}


			const response = await selfApi.login(this.loginData.login, this.loginData.password)
			if (response.login) {
				this.loginData.error = response.login
			} else {
				if (response.code === 'success') {
					window.location = response.redirect
				}
			}
		}
	},
	computed: {
		appLogo () {
			return require('~/assets/img/logo_alt.png');
		},
		appLogoLight () {
			return require('~/assets/img/logo.png');
		}
	}
}
</script>


<style lang="scss">
	@import "~scss/common/md_colors";
	@import "~scss/common/variables_mixins";
	@import "~scss/pages/login";
	.sc-login-page {

		&-wrapper {
			height: calc(100vh - 48px);
			@include respond-below(medium) {
				height: auto;
				align-items: flex-start;
			}
		}
		&-inner {
			padding: 16px;
			@include respond-below(medium) {
				padding: 8px 0;
			}
		}
		&-logo {
			padding: 24px;
			margin-bottom: 16px;
			text-align: center;
			@include respond-below(medium) {
				padding: 12px 24px;
				margin-bottom: 8px;
			}
			&-light {
				display: none;
			}
		}
		&.sc-theme-dark {
			body {
				background: material-color('grey', '900');
			}
			a {
				&:not(.sc-button) {
					color: #62b5ff;
					&:hover,
					&:focus {
						color: #1e87f0;
					}
				}
			}
			.sc-link {
				color: #62b5ff !important;
				&:hover,
				&:focus {
					color: #1e87f0 !important;
				}
			}
			.sc-color-secondary {
				color: rgba(255,255,255,.67) !important;
			}
			.uk-input,
			.uk-select,
			.uk-textarea {
				background: material-color('grey', '600');
				color: $white;
			}
			.sc-input-wrapper > label {
				color: rgba(255,255,255,.5);
			}
			.uk-card {
				background: material-color('grey', '800');
				color: $white;
			}
			.sc-login-page-logo {
				display: none;
				&-light {
					display: block;
				}
			}
		}
	}
</style>

<template>
	<span class="sc-progress-label">
		<slot></slot>
	</span>
</template>

<script>
export default {
	name: 'ScProgressLabel'
}
</script>

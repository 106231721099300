<script>
import contentOverlay from '~/components/Overlay'
import Datatable from  '~/components/datatables/Datatables'
import {selfApi} from "~/api";
import {mapState} from "vuex";
import moment from 'moment'
import 'moment/locale/ru'; // Импортируйте локаль
export default {
	name: "journalRequests",
	components: {
		contentOverlay,
		Datatable
	},
	data: () => ({
		contentOverlayActive: false,
		progressActive: false,
		columns: {},
		tableData:[]

	}),
	computed: {
		dtBOptions() {
			return {
				columnDefs: [
					{target: 0 ,render:function(data, type, row) {
							// Для отображения используем форматированную дату
							if (type === 'display') {
								return moment(data).fromNow(); // "2 недели назад"
							}
							// Для расчетов возвращаем оригинальную дату
							return data; // Возвращаем оригинальное значение для сортировки
						}
					}  // укажите индекс столбца или его CSS-класс
				],
				"scrollY": "75vh",
				order: [[0, 'desc']],
				"scrollCollapse": true,
				"paging": false,
				language: {
					emptyTable: "Нет записей, удовлетворяющих условию",
					info: "Записи с _START_ по _END_ из _TOTAL_",
					infoEmpty: "Записей не найдено",
					lengthMenu: "Показывать: _MENU_ ",
					infoFiltered: " - отфильтровано из  _MAX_ записей",
					zeroRecords: "Нет записей, удовлетворяющих критериям поиска",
					processing: "Получение данных из БД...",
					paginate: {
						first: "Первая страница",
						last: "Последняя страница",
						next: "Следующая",
						previous: "Предыдущая"
					},
					search: "Поиск",
					buttons: {
						colvis: 'Выбор столбцов'
					}
				},
				columns: this.columns,  // Теперь `this.columns` доступен
				responsive: 'responsiveModal'
			};
		},
		...mapState([
			'vxSidebarMainExpanded',
			'vxOffcanvasPresent',
			'vxOffcanvasExpanded',
			'vxTopMenuActive',
			'vxActiveLocale',
			'vxSidebarMiniActive',
			'vxLayoutBoxedActive'
		])
	},

	async mounted() {
		const tableBody = document.querySelector('#journalEntry');

		this.$store.commit('togglePageOverlay', true)
		this.$store.commit('toggleProgressOverlay', true);
		this.columns = await selfApi.getColumnsJournal()
		const response = await selfApi.getDataJournal(this.columns)
		this.tableData = response.data
		this.$store.commit('toggleProgressOverlay', false);
		this.$store.commit('togglePageOverlay', false)
		tableBody.addEventListener('click', async (event) => {
			const button = event.target.closest('button[data-action="drop-journal-entry"]');
			if (button) {
				const code = button.getAttribute('data-code'); // Получаем значение data-code
				this.$store.commit('togglePageOverlay', true)
				this.$store.commit('toggleProgressOverlay', true);
				const dropResponse = await selfApi.dropJournalEntry(code)
				this.tableData = []
				const response = await selfApi.getDataJournal(this.columns)
				this.tableData = response.data
				this.$store.commit('toggleProgressOverlay', false);
				this.$store.commit('togglePageOverlay', false)
			}
		});


	}
}
</script>

<template>
	<div id="journalEntry">
		<Datatable v-if="tableData.length" id="sc-dt-scroll-v"
			   ref="vScrollTable"
			   :options="dtBOptions"
				   :data="tableData"
		></Datatable>
	</div>
</template>

<style scoped lang="scss">

</style>

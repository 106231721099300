<template>
    <div>
		<Header ref="mainHeader" />
		<Sidebar  v-if="vxSidebarMainExpanded" />
		<Footer v-if="vxFooterActive"></Footer>
		<FancyFooter v-if="vxFancyFooterActive"></FancyFooter>
		<div v-if="vxPageOverlay" class="sc-overlay dimmed sc-overlay-page"></div>
		<ScProgressOverlayRoot />
    </div>
  </template>

  <script >

  import { mapState } from 'vuex'
  import Header from "~/components/Header.vue";
  import FancyToolbar from '~/components/FancyToolbar.vue';
  import Sidebar from '~/components/Sidebar.vue';
  import Footer from '~/components/Footer.vue';
  import FancyFooter from '~/components/FancyFooter.vue';
  import { ScProgressOverlayRoot } from '~/components/progress'

  export default {
    name: 'App',
	  components: {
		  Header,
		  FancyToolbar,
		  Sidebar,
		  Footer,
		  FancyFooter,
		  ScProgressOverlayRoot

	  },
	  computed: {
		  ...mapState([
			  'vxFancyToolbarActive',
			  'vxSidebarMainExpanded',
			  'vxFooterActive',
			  'vxFancyFooterActive',
			  'vxPageOverlay'
		  ])
	  },

  }
  </script>


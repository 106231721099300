<script>
export default {
	name: "requestForm",
	components:{},
	data: () => ({
		loading: false,
		result:{
			count: -1,
			export: -1,
			import: -1,
			error: '',
			info:''
		},


	}),
	computed: {
		canSave() {
			return (this.result.error === '' && this.result.count > 0)
		},
		btnText() {
			return this.loading ? 'Поиск поставок ...' :'Поиск'
		},
		showBottom() {
			return !this.loading && (this.result.count >=0 || this.result.error)
		},
		goodRecords() {
			return this.getGoodString(this.result.count, ['запись', 'записи','записей'])
		},
		goodCompaniesImport() {
			return this.getGoodString(this.result.export, ['компания-экспортер', 'компании-экспортера','компаний-экспортеров'])
		},
		goodCompaniesExport() {
			return this.getGoodString(this.result.import, ['компания-импортер', 'компании-импортера','компаний-импортеров'])
		},
	},
	methods: {
		getGoodString(counter, textForms) {
			let lastCounter = Math.abs(counter) % 100;
			let n1 = lastCounter % 10;
			if (lastCounter > 10 && lastCounter < 20) {
				return counter + ' ' + textForms[2];
			}
			if (n1 > 1 && n1 < 5) {
				return counter + ' ' + textForms[1];
			}
			if (n1 === 1) {
				return counter + ' ' + textForms[0];
			}
			return counter + ' ' + textForms[2];
		},
		resetResult(){
			this.result = {
				count: -1,
				export: -1,
				import: -1,
				error: '',
				info:''
			}
		},
		sendForm() {
			this.setLoading(true)
			const self = this
			this.resetResult()
			$.ajax({
				type: 'POST',
				dataType: "json",
				url: '',
				data: $('#requestForm').serialize(),
				success: function (data) {

					self.result.count = data.count
					self.result.error = data.error_message

					if (data.can_save > 0) {
						if (data.count !== data.can_save) {
							self.result.info += `будет сохранено ${data.can_save} записей`;
						}
					} else if(self.result.error === '' && data.count) {

						self.result.error = 'Лимит записей исчерпан'
					}

					if (data.firms) {
						if (data.firms.importerCount) {
							self.result.import = data.firms.importerCount
						}
						if (data.firms.exporterCount) {
							self.result.export = data.firms.exporterCount
						}
					}
					self.setLoading(false)
					$("html, body").animate({ scrollTop: $(document).height() }, 1000);
				},
				error: function (xhr, str) {
					self.setLoading(false)
					self.setLoading(false)
					self.result.error = 'Возникла ошибка, ' + xhr.status
					self.result.count = -1
				}
			});

		},
		saveForm() {
			$('#requestForm').submit()
		},

		setLoading(status) {
			this.$store.commit('togglePageOverlay', !!status)
			this.$store.commit('toggleProgressOverlay', !!status);
			this.loading = status

		}
	}
}
</script>

<template>
	<div>
		<form action="" method="post" id="requestForm" class="uk-form-stacked">
			<div class="md-bg-grey-100 sc-padding">
				<slot></slot>
				<div class="uk-margin-top">
					<input @click="sendForm" class="sc-button sc-button-large sc-button-primary" type="button"
						   autocomplete="off"
						   :value="btnText">
				</div>

			</div>

		</form>
		<div class="md-bg-grey-100 uk-margin-top sc-padding" v-if="this.showBottom">
			<div class="uk-alert-danger uk-text-center" data-uk-alert v-if="result.error">
				<h5>{{result.error}}</h5>
			</div>
			<div class="uk-text-center uk-margin-top" v-if="result.count >= 0" >
				<h3>Результаты поиска</h3>
				<div class="uk-margin-large-top uk-text-xlarge">
					Найдено {{goodRecords}}
				</div>
				<div class="uk-margin-small-top uk-text-large"  v-if="result.export >= 0">
					Найдено {{goodCompaniesExport}}
				</div>
				<div class="uk-margin-small-top uk-text-large"  v-if="result.import >= 0">
					Найдено {{goodCompaniesImport}}
				</div>
			</div>
			<div data-uk-alert="" class="uk-margin uk-text-center uk-text-large uk-alert-warning uk-alert" v-if="result.info">
				{{ result.info }}
			</div>
			<div class="uk-margin-large-top  uk-text-center" v-if="canSave">
				<button @click="saveForm" id="btnSave" class="sc-button sc-button-large sc-button-primary uk-width-1-1 waves-effect waves-button waves-light" >Сохранить
					результаты
				</button>
			</div>
		</div>

	</div>
</template>

<style scoped lang="scss">

</style>

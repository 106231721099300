<script>
import ScInput from '~/components/Input'
import '~/plugins/flatpickr'
import {Russian} from 'flatpickr/dist/l10n/ru'
export default {
	name: "CustomData",
	components:{
		ScInput,
	},
	props: {
		name: String,
		label: String,
		minDate: {
			type: String,
			default: '',
		},
		maxDate: {
			type: String,
			default: '',
		},
		defaultValue: {
			type: String,
			default: '',
		},
	},


	watch: {
		// Следим за изменениями prop и обновляем inputValue
		defaultValue(newVal) {
			this.inputValue = newVal;
		}
	},
	computed: {
		Russian() {
			return Russian
		},
		Options() {
			let options = {
				'locale': this.Russian,
				'dateFormat': 'd.m.Y'
			}
			if (this.minDate) {
				options.minDate = this.minDate
			}
			if (this.maxDate) {
				options.maxDate = this.maxDate
			}
			return options

		},

	},

	data() {
		return {
			inputValue: this.defaultValue // Устанавливаем начальное значение из пропса
		};
	},
}
</script>

<template>
	<div>
		<sc-input v-model="inputValue" :name="name"  v-flatpickr="Options"  :placeholder="label" mode="outline"></sc-input>
	</div>

</template>

<style scoped lang="scss">

</style>

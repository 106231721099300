<script>
import contentOverlay from '~/components/Overlay'
import Datatable from  '~/components/datatables/Datatables'
import {ScCard, ScCardHeader,ScCardBody } from "~/components/card";
import {selfApi} from "~/api";
import ChartJsPie from '~/components/chartjs/Pie'
import { scColors } from '~/assets/js/utils';
export default {
	name: "analyticsRequest",
	components: {
		contentOverlay,
		Datatable,
		ScCardHeader,
		ScCard,
		ScCardBody,
		ChartJsPie
	},
	data: () => ({
		tableData: [],
		loading: true,
		columns: [],
		columns_original: [],
		graphics: [],


	}),
	props: {
		code: String,
		currentPath: String,
		originPath: String,
		parameters: Array,
	},

	computed: {
		dtOptions() {
			const id = this.code
			const current_path = this.currentPath
			return {

				columns: this.columns,  // Теперь `this.columns` доступен
				responsive: false,
				processing: true,
				serverSide: true,
				deferRender: true,
				order: [[1, 'asc']],
				buttons: [
					{
						className: "sc-button go_to_request",
						text: 'Поставки '
					},
					{
						extend: "csvHtml5",
						className: "sc-button",
						text: 'Выгрузить в CSV '
					},
					{
						extend: "excelHtml5",
						className: "sc-button",
						text: 'Выгрузить в XLSX '
					},
					{
						className: "sc-button modal-request-analytic",
						text: 'Новый аналитический отчет', // Текст на кнопке
					},
					{
						className: "sc-button modal-request-parameter",
						text: 'Параметры запроса', // Текст на кнопке
					},

					{
						extend: "pdfHtml5",
						className: "sc-button sc-button-icon",
						text: '<i class="mdi mdi-file-pdf md-color-red-800"></i>'
					},
					{
						extend: "print",
						className: "sc-button sc-button-icon",
						text: '<i class="mdi mdi-printer"></i>',
						title: 'Custom Title',
						messageTop: 'Custom message on the top',
						messageBottom: 'Custom message on the bottom',
						autoPrint: true
					}
				],
				ajax: {
					url: current_path,
					type: "POST"
				},
				language: {
					emptyTable: "Нет записей, удовлетворяющих условию",
					info: "Записи с _START_ по _END_ из _TOTAL_",
					infoEmpty: "Записей не найдено",
					lengthMenu: "Показывать: _MENU_ ",
					infoFiltered: " - отфильтровано из  _MAX_ записей",
					zeroRecords: "Нет записей, удовлетворяющих критериям поиска",
					processing: "Получение данных из БД...",
					paginate: {
						first: "Первая страница",
						last: "Последняя страница",
						next: "Следующая",
						previous: "Предыдущая"
					},
					search: "Поиск",
					buttons: {
						colvis: 'Выбор столбцов'
					}
				},
			};
		},

	},
	async mounted() {
		this.setLoading(true)
		this.columns = await selfApi.getColumnsRequestAnalytics(this.currentPath)
		this.columns_original = await selfApi.getColumnsRequest(this.code)
		this.setLoading(false)
	},
	methods:{

		getNameChart(key) {
			return this.columns.filter((item)=>{
				return item.name === key
			})[0].title
		},
		drawGraphs(datas) {
			this.graphics = []
			for (const [key, value] of Object.entries(datas.graphs)) {
				this.graphics.push({
					options:  {
						responsive: true,
						maintainAspectRatio: false,
						legend: {
							position: 'right'
						},
						title: {
							display: true,
							text: this.getNameChart(key)
						}
					},
					chartData: {
						datasets: [{
							data: value.map(item => item[1]),
							backgroundColor: scColors.multi,
						}],
						labels: value.map(item => item[0])
					}
				})
			}

		},
		tableInited( json){
			const self= this
			// console.log( json)
			this.drawGraphs(json)
			this.$refs.vScrollTable.$dt.buttons().container().appendTo(document.getElementById('analyticsEntry__buttons'))
			$('.modal-request-parameter').attr('data-uk-toggle', 'target: #modal-request-parameter');
			$('.modal-request-analytic').attr('data-uk-toggle', 'target: #modal-request-analytic');
			$('.go_to_request').click(() => {
				window.location = this.originPath
			});
			$('#analyticsEntry tbody').on('click', 'td.details-control', function () {
				var tr = $(this).closest('tr');
				var row = self.$refs.vScrollTable.$dt.row(tr);

				if (row.child.isShown()) {
					// This row is already open - close it
					row.child.hide();
					tr.removeClass('shown');
				} else {
					// Open this row
					let rowData = row.data();
					$.ajax({
						dataType: "json",
						url: self.currentPath,
						method: 'post',
						data: {
							group_key: rowData.group_key
						},
						success: function (data) {
							let childRows = [];
							data.data.forEach(
								function (d) {
									let r = $('<tr/>');

									self.columns.forEach(
										function (column) {
											if (column.data !== undefined) {
												let c = $('<td/>');
												c.html(d[column.data]);
												r.append(c);
											}
										}
									);

									childRows.push(r);
								}
							);
							row.child(childRows).show();
							tr.addClass('shown');
						}
					});
				}
			});
		},
		setLoading(status) {
			this.$store.commit('togglePageOverlay', !!status)
			this.$store.commit('toggleProgressOverlay', !!status);
			this.loading = !!status
		},
		createAnalytics() {
			window.location.href = selfApi.BASE_URL + '/journal/' + this.code  + '/analytics/' + $('#analyticsField1').val() + '/' + $('#analyticsField1').val();
		}
	}
}
</script>

<template>
	<div>
		<ScCardHeader separator>
			<div class="uk-flex-middle uk-grid-small uk-grid" data-uk-grid>
				<div class="uk-flex-1">
				</div>
				<div class="uk-width-auto@s">
					<div id="analyticsEntry__buttons"></div>
				</div>
			</div>
		</ScCardHeader>
		<div id="analyticsEntry" class="tableData-nowrap">
			<Datatable v-if="!loading" id="requestDataTable"

					   ref="vScrollTable"
					   :options="dtOptions"
					   :buttons="true"
					   :data="[]"
					   @init-complete="tableInited"
			></Datatable>
		</div>
		<div v-if="!loading" class="uk-child-width-1-2 uk-grid" data-uk-grid>
			<div v-for="chart in graphics">
				<ScCard>
					<ScCardBody class="sc-chart-chartjs">
						<ChartJsPie  :data="chart.chartData" :options="chart.options"></ChartJsPie>
					</ScCardBody>
				</ScCard>
			</div>

		</div>

		<div id="modal-request-parameter" data-uk-modal>
			<div class="uk-modal-dialog uk-modal-body">
				<button class="uk-modal-close-default" type="button" data-uk-close></button>
				<h2 class="uk-modal-title">
					Параметры запроса
				</h2>
				<div>
					<ul class="uk-list uk-list-striped" >
						<li  data-uk-grid v-for="parameter in parameters">
							<div class="uk-width-1-3"><b>{{ parameter.name }}:</b></div>
							<div class="uk-flex-1 ">{{ parameter.value }}</div>
						</li>
					</ul>
				</div>

			</div>
		</div>
		<div id="modal-request-analytic" data-uk-modal>
			<div class="uk-modal-dialog uk-modal-body">
				<button class="uk-modal-close-default" type="button" data-uk-close></button>
				<h2 class="uk-modal-title">
					Выберите параметры группировки
				</h2>
				<div>
					<div class="uk-margin">
						<select id="analyticsField1" class="uk-select">
							<option v-for="column in columns_original" v-if="column.analytics === 'detail'" :value="column.name">{{column.title}}</option>
						</select>
					</div>
				</div>
				<p class="uk-text-right">
					<button class="sc-button sc-button-default sc-button-flat uk-modal-close" type="button">
						Отмена
					</button>
					<button class="sc-button sc-button-primary" @click="createAnalytics" type="button">
						Сформировать
					</button>
				</p>

			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>

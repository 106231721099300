var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"card",staticClass:"uk-card",class:[
		_vm.cardClass,
		_vm.cardFullScreenClass,
		_vm.cardFullScreenAnimateClass,
		{'sc-card-loading': _vm.loading},
		{'sc-card-minimized': _vm.collapsed},
		{'uk-card-hover': _vm.hover}
	]},[_vm._t("default"),(_vm.loading)?_c('div',{staticClass:"sc-progress-card",style:(_vm.progressStyle)},[_c('ScProgressCircular',{attrs:{"size":"lg"}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }


export const selfApi = {
	BASE_URL: '',
	getCsrfToken() {
		const metaEl = document.querySelector('meta[name="csrf-token"]');
		if (metaEl) {
			return metaEl.getAttribute('content')
		}
		return ''
	},

	addTokenToForm(data) {
		const token = this.getCsrfToken()
		if (token) {
			data.append( '_token', token)
		}
	},

	async login(login, password) {
		const data = new FormData
		data.append('login', login);
		data.append('password', password);
		this.addTokenToForm(data)


		let response = await fetch( this.BASE_URL + '/login', {
			method: 'POST',
			headers: {
				// 'Content-Type': 'application/json;charset=utf-8',
				'X-Requested-With': 'XMLHttpRequest'
			},
			body: data,
			credentials: 'include' // Эта опция отправляет cookies
		});
		return  await response.json();
	},

	async getColumnsJournal() {
		let response = await fetch( this.BASE_URL + '/app/columns', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
				// 'X-Requested-With': 'XMLHttpRequest'
			},
			credentials: 'include' // Эта опция отправляет cookies
		});
		return response.json()
	},
	async getDataJournal(columns) {
		let response = await fetch( this.BASE_URL + '/app/journal', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			credentials: 'include' // Эта опция отправляет cookies
		});
		return await response.json()
	},

	async dropJournalEntry(code){
		let response = await fetch( this.BASE_URL + '/app/journal-drop', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			credentials: 'include', // Эта опция отправляет cookies
			body: JSON.stringify({code})
		});
		if (response.statusCode !== 200) {
			return 'error'
		}
		return await response.json()
	},

	async getColumnsRequest(code) {
		let response = await fetch( this.BASE_URL + '/app/request/' + code + '/columns', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			credentials: 'include',
		});
		return await response.json()
	},
	async getColumnsRequestAnalytics(path) {
		let response = await fetch( path + '/columns', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			credentials: 'include',
		});
		return await response.json()
	}


}

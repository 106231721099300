<script>
import contentOverlay from '~/components/Overlay'
import Datatable from  '~/components/datatables/Datatables'
import {ScCard, ScCardHeader,ScCardBody } from "~/components/card";
import {selfApi} from "~/api";
import {mapState} from "vuex";

export default {
	name: "requestTable",
	components: {
		contentOverlay,
		Datatable,

	},
	data:() => ({
		tableData: [],
		loading: true,
		columns:[]
	}),
	props: {
		code: String,
		parameters: Array,
	},
	computed: {
		dtOptions() {
			const id = this.code
			return {
				processing: true,
				serverSide: true,
				responsive: false,
				deferRender: true,
				columns: this.columns,  // Теперь `this.columns` доступен
				scrollY: (document.documentElement.scrollHeight - 320) + 'px',
				scrollX: true,
				pageResize: true,
				paging: true,
				start: 0,
				length: 100,
				dom: 'bfrti', // Это важно для кнопок,
				searching: true, // Отключение поиска
				stateSave: false,
				scroller: true,
				buttons: [
					{
						extend: "csvHtml5",
						className: "sc-button",
						text: 'Выгрузить в CSV '
					},
					{
						extend: "excelHtml5",
						className: "sc-button",
						text: 'Выгрузить в XLSX '
					},
					{
						className: "sc-button modal-request-analytic",
						text: 'Создать аналитический отчет', // Текст на кнопке
					},
					{
						className: "sc-button modal-request-parameter",
						text: 'Параметры запроса', // Текст на кнопке
					},

					{
						extend: "pdfHtml5",
						className: "sc-button sc-button-icon",
						text: '<i class="mdi mdi-file-pdf md-color-red-800"></i>'
					},
					{
						extend: "print",
						className: "sc-button sc-button-icon",
						text: '<i class="mdi mdi-printer"></i>',
						title: 'Custom Title',
						messageTop: 'Custom message on the top',
						messageBottom: 'Custom message on the bottom',
						autoPrint: true
					}
				],
				ajax: {
					url: '/journal/' + this.code ,
					type: "POST",
					data: function (data) {
						console.log(data)
						// data.ajax = true
						data.id = id;
					}
				},
				language: {
					emptyTable: "Нет записей, удовлетворяющих условию",
					info: "Записи с _START_ по _END_ из _TOTAL_",
					infoEmpty: "Записей не найдено",
					lengthMenu: "Показывать: _MENU_ ",
					infoFiltered: " - отфильтровано из  _MAX_ записей",
					zeroRecords: "Нет записей, удовлетворяющих критериям поиска",
					processing: "Получение данных из БД...",
					paginate: {
						first: "Первая страница",
						last: "Последняя страница",
						next: "Следующая",
						previous: "Предыдущая"
					},
					search: "Поиск"
				},
			};
		},
	},
	async mounted() {
		this.setLoading(true)
		this.columns = await selfApi.getColumnsRequest(this.code)
		this.setLoading(false)
	},
	methods:{
		tableInited(){
			this.$refs.vScrollTable.$dt.buttons().container().appendTo(document.getElementById('journalEntry__buttons'))
			$('.modal-request-parameter').attr('data-uk-toggle', 'target: #modal-request-parameter');
			$('.modal-request-analytic').attr('data-uk-toggle', 'target: #modal-request-analytic');

		},
		setLoading(status) {
			this.$store.commit('togglePageOverlay', !!status)
			this.$store.commit('toggleProgressOverlay', !!status);
			this.loading = !!status
		},
		createAnalytics() {
			window.location.href = selfApi.BASE_URL + '/journal/' + this.code  + '/analytics/' + $('#analyticsField1').val() + '/' + $('#analyticsField1').val();
		}
	}
}
</script>

<template>
	<div>
		<ScCardHeader separator>
			<div class="uk-flex-middle uk-grid-small uk-grid" data-uk-grid>
				<div class="uk-flex-1">
				</div>
				<div class="uk-width-auto@s">
					<div id="journalEntry__buttons"></div>
				</div>
			</div>
		</ScCardHeader>
		<div id="journalEntry" class="tableData-nowrap">
			<Datatable v-if="!loading" id="requestDataTable"

					   ref="vScrollTable"
					   :options="dtOptions"
					   :buttons="true"
					   :data="[]"
					   @init-complete="tableInited"
			></Datatable>
		</div>


		<div id="modal-request-parameter" data-uk-modal>
			<div class="uk-modal-dialog uk-modal-body">
				<button class="uk-modal-close-default" type="button" data-uk-close></button>
				<h2 class="uk-modal-title">
					Параметры запроса
				</h2>
				<div>
					<ul class="uk-list uk-list-striped" >
						<li  data-uk-grid v-for="parameter in parameters">
							<div class="uk-width-1-3"><b>{{ parameter.name }}:</b></div>
							<div class="uk-flex-1 ">{{ parameter.value }}</div>
						</li>
					</ul>
				</div>

			</div>
		</div>
		<div id="modal-request-analytic" data-uk-modal>
			<div class="uk-modal-dialog uk-modal-body">
				<button class="uk-modal-close-default" type="button" data-uk-close></button>
				<h2 class="uk-modal-title">
					Выберите параметры группировки
				</h2>
				<div>
					<div class="uk-margin">
						<select id="analyticsField1" class="uk-select">
							<option v-for="column in columns" v-if="column.analytics === 'detail'" :value="column.name">{{column.title}}</option>
						</select>
					</div>
				</div>
				<p class="uk-text-right">
					<button class="sc-button sc-button-default sc-button-flat uk-modal-close" type="button">
						Отмена
					</button>
					<button class="sc-button sc-button-primary" @click="createAnalytics" type="button">
						Сформировать
					</button>
				</p>

			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
body{
	background-color: red;
}
	#requestDataTable td{
		white-space: nowrap;
	}
</style>

import Vue from 'vue'
import Vuex from "vuex";

import 'uikit/dist/css/uikit.css'
import '~/assets/scss/main.scss'

import '~/plugins/components.global.js'
import '~/plugins/directives.client.js'
import '~/plugins/filters.js'
import '~/plugins/mixins.client.js'
import '~/plugins/waves.client.js'
import '~/plugins/retina.client.js'
import '~/plugins/vueVisible.client.js'
import '~/plugins/vueVisible.client.js'



import App from "@/App.vue";
import {store} from "~/store";

import VueRouter from 'vue-router'
import { mapState } from 'vuex'
import FancyTree from "~/components/FancyTree.vue";
import Header from "~/components/Header.vue";
import LoginPage from "~/pages/login_page.vue";
import FancyToolbar from '~/components/FancyToolbar.vue';
import Sidebar from '~/components/Sidebar.vue';
import Footer from '~/components/Footer.vue';
import FancyFooter from '~/components/FancyFooter.vue';
import { ScProgressOverlayRoot } from '~/components/progress'
import Datatable from '~/components/datatables/Datatables'
import JournalRequests from "~/pages/journalRequests.vue";
import RequestTable from "~/pages/requestTable.vue";
import AnalyticsRequest from "~/pages/analyticsRequest.vue";
import CustomData from "~/components/CustomData.vue";
import SelectField from "~/SelectField.vue";
import requestForm from "~/pages/requestForm.vue";
import ScInput from '~/components/Input'
import { scMq } from '~/assets/js/utils'




Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: "/",
});
document.addEventListener('DOMContentLoaded', function () {
	new Vue({
		el: '#app', // Монтируем Vue прямо на существующий #app
		store,
		router,
		data() {
			return {
				menuContents: [],
				userLimits: {},
				footerMenu:[],
				sideMenu:[],
				treeMSel:{},
				ratingData:[],
				ratingTableOptions:{},
				user_request:[],
				HTMLClasses: {
					activeTheme: '',
					pageFixedClass: '',
					cardFixedClass: '',
					headerExpandedClass: '',
					sidebarMainExpanded: '',
					sidebarOffcanvas: '',
					cmFullscreenClass: '',
					footerActive: '',
					fullWidth: '',
					sidebarMini: '',
					fancyToolbar: ''
				},
			}
		},
		created() {
			this.menuContents = window.menuContents || [];
			this.userLimits = window.userLimits || {};
			this.footerMenu = window.footerMenu || {};
			this.sideMenu = window.sideMenu || {};
			this.sideMenuBottom = window.sideMenuBottom || {};
			this.treeMSel = window.treeMSel || {};
			this.ratingData = window.ratingData || [];
			this.ratingTableOptions = window.ratingTableOptions || [];
			this.user_request = window.user_request || [];
		},
		methods: {
			getBackgroundImage (image) {
				return require('~/assets/img/photos/' + image)
			},
			dtButtonsInitialized () {
				// append buttons to custom container
				this.$refs.buttonsTable.$dt.buttons().container().appendTo(document.getElementById('sc-dt-buttons'))
			},
			mergeHTMLClasses () {
				const HTML = document.getElementsByTagName("html")[0] || document.querySelector("html");
				HTML.className = Object.keys(this.HTMLClasses).map(key => { return this.HTMLClasses[key] }).join(' ').trim();
			}
		},
		components: {
			ScInput,
			AnalyticsRequest,
			RequestTable,
			JournalRequests,
			VedHeader: Header,
			VedFooter: Footer,
			FancyToolbar,
			VedSidebar: Sidebar,
			RestTable:Datatable,
			LoginPage,
			FancyFooter,
			ScProgressOverlayRoot,
			FancyTree,
			CustomData,
			// CustomSelect,
			SelectField,
			requestForm
		},
		computed: {
			...mapState([
				'vxFancyToolbarActive',
				'vxSidebarMainExpanded',
				'vxFooterActive',
				'vxFancyFooterActive',
				'vxPageOverlay'
			])
		},
		watch: {
			'vxSidebarMainExpanded' (value) {
				this.HTMLClasses.sidebarMainExpanded = scMq.mediumMin()
					?
					value ? '' : 'sc-sidebar-main-slide'
					:
					value ? 'sc-sidebar-main-visible' : '';
				this.mergeHTMLClasses()
			},
		},
	})
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{class:{
		'sc-sidebar-menu uk-nav': _vm.isParent,
		'sc-sidebar-menu-sub': !_vm.isParent
	}},_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:index,class:{
			'sc-has-submenu': item.submenu && item.submenu.length,
			'sc-page-active': _vm.$route.path === item.page || _vm.pageChild(item),
			'sc-section-active': item.isOpen,
			'sc-sidebar-menu-heading': item.section_title,
			'sc-sidebar-menu-separator': item.separator
		}},[(item.section_title)?_c('span',[_vm._v(" "+_vm._s(item.section_title)+" ")]):_vm._e(),(item.page && !item.submenu)?_c('a',{attrs:{"id":item.id,"href":item.page}},[(item.tag)?_c('span',{staticClass:"uk-label"},[_vm._v(" "+_vm._s(item.tag)+" ")]):_vm._e(),(item.icon)?_c('span',{staticClass:"uk-nav-icon"},[_c('i',{class:item.icon})]):_vm._e(),_c('span',{staticClass:"uk-nav-title"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e(),(item.submenu)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSection($event, item)}}},[(item.icon)?_c('span',{staticClass:"uk-nav-icon"},[_c('i',{class:item.icon})]):_vm._e(),_c('span',{staticClass:"uk-nav-title"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e(),(item.page === '' && !item.submenu)?_c('a',{attrs:{"id":item.id,"href":"javascript:void(0)"}},[(item.icon)?_c('span',{staticClass:"uk-nav-icon"},[_c('i',{class:item.icon})]):_vm._e(),_c('span',{staticClass:"uk-nav-title"},[_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e(),_c('transition',{attrs:{"css":false},on:{"enter":_vm.tEnter,"enter-cancelled":_vm.tEnterCancelled,"leave":_vm.tLeave,"leave-cancelled":_vm.tLeaveCancelled}},[(item.submenu && item.submenu.length)?_c('ScMenuList',{directives:[{name:"show",rawName:"v-show",value:(item.isOpen),expression:"item.isOpen"}],attrs:{"menu-data":item.submenu}}):_vm._e()],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }